<template>
  <div class="container">
    <div class="row align-items-center text-center">
      <div class="col-12 col-lg-2">
        <p>{{getTrans('messages.sub_id_num', 1)}}</p>
        <el-input
          size="medium"
          v-model="custominput1"
          @change="valueChange"
        ></el-input>
      </div>
      <div class="col-12 col-lg-2">
        <p>{{getTrans('messages.sub_id_num', 2)}}</p>
        <el-input
          size="medium"
          v-model="custominput2"
          @change="valueChange"
        ></el-input>
      </div>
      <div class="col-12 col-lg-2">
        <p>{{getTrans('messages.sub_id_num', 3)}}</p>
        <el-input
          size="medium"
          v-model="custominput3"
          @change="valueChange"
        ></el-input>
      </div>
      <div class="col-12 col-lg-2">
        <p>{{getTrans('messages.sub_id_num', 4)}}</p>
        <el-input
          size="medium"
          v-model="custominput4"
          @change="valueChange"
        ></el-input>
      </div>
      <div class="col-12 col-lg-2">
        <p>{{getTrans('messages.sub_id_num', 5)}}</p>
        <el-input
          size="medium"
          v-model="custominput5"
          @change="valueChange"
        ></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import { ElInput } from "element-plus";
export default {
  name: "custom-tracking",
  components: {
    [ElInput.name]: ElInput,
  },
  data() {
    return {
      custominput1: "",
      custominput2: "",
      custominput3: "",
      custominput4: "",
      custominput5: ""
    };
  },
  methods: {
    valueChange() {
      let values = {};
      this.custominput1 ? (values.subid1 = this.custominput1) : null;
      this.custominput2 ? (values.subid2 = this.custominput2) : null;
      this.custominput3 ? (values.subid3 = this.custominput3) : null;
      this.custominput4 ? (values.subid4 = this.custominput4) : null;
      this.custominput5 ? (values.subid5 = this.custominput5) : null;
      this.$emit("updated", values);
    }
  },
  //TODO: mounted
};
</script>
