<template>
  <div class="col-12">
    <div>
      <p class="mb-0">{{getTrans('messages.enter_url')}}</p>
    </div>
    <div>
      <!-- eslint-disable -->
      <input
        @change="update"
        type="text"
        placeholder="http://fallbackurl.com"
        class="form-control"
        style="width: 100%;"
        v-model="url"
      />
      <!-- eslint-enabled -->
    </div>
  </div>
</template>
<script>
export default {
  name: "fallback-url",
  data() {
    return {
      url: ""
    };
  },
  methods: {
    update() {
      this.$emit("updated", this.url);
    }
  },
  //TODO: mounted
};
</script>
