<template>
  <div class="col-12">
    <el-collapse-item :title="getTrans('messages.fallback_options')" name="4">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-12 col-lg-3">
              <div class="row align-items-center mt-1 mb-3">
                <div class="col-12">
                  <base-button type="secondary">{{getTrans('messages.relax')}}</base-button>
                </div>
              </div>
              <div class="row align-items-center mb-3">
                <div class="col-12">
                  <base-button type="secondary">{{getTrans('messages.repeat')}}</base-button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-9">
              <div class="row align-items-center">
                <div class="col-12">
                  <p>Test title</p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Maecenas lobortis nisi sed posuere auctor. Sed in urna
                    volutpat, viverra risus a, rutrum urna. Suspendisse ac
                    tempus eros, nec aliquam nibh. Ut quis porttitor quam.
                    Vestibulum lacinia sem nunc, quis pharetra risus eleifend
                    et. Pellentesque suscipit lorem at convallis ultricies. Nunc
                    lobortis rutrum lorem et sagittis. Donec vitae mattis diam,
                    sit amet gravida ipsum. Interdum et malesuada fames ac ante
                    ipsum primis in faucibus. Sed elementum nisi nibh, id
                    imperdiet elit ultricies eu. Aenean massa enim, dictum ut
                    vulputate ac, tristique vel sem. Nunc non ultricies tellus.
                    Aenean eget sollicitudin dolor. Orci varius natoque
                    penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Aenean sapien elit, tincidunt egestas mi id,
                    venenatis egestas tortor. Integer pulvinar neque neque,
                    vitae malesuada mauris pretium non.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <base-button type="secondary" @click="closeCollapse"
          >{{getTrans('messages.cancel')}}</base-button
        >
        <base-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</base-button>
      </div>
    </el-collapse-item>
  </div>
</template>
<script>
export default {
  name: "fallback-options"
};
</script>
