<template>
  <el-collapse accordion v-model="activeNames">
    <div v-if="showModules.indexOf('camsites') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.select_camsites')" name="1" aria-expanded="false">
        <template #title>
          <div>{{getTrans('messages.select_camsites')}}</div>
        </template>
        <select-camsites @updated="selectedCamsitesUpdated"></select-camsites>
        <div class="row justify-content-center mt-2">
          <el-button type="secondary" @click="closeCollapse">{{getTrans('messages.cancel')}}</el-button>
          <el-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</el-button>
        </div>
      </el-collapse-item>
    </div>
    <div v-if="showModules.indexOf('profiles') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.select_profiles')" name="2">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-12 col-lg-3 text-center mb-2">
              <el-button type="primary" class="mr-3" @click="selectAllProfiles"
                >{{getTrans('messages.select_all')}}</el-button
              >
            </div>
            <el-input
              v-model="currentSearch"
              @change="search"
              label="Search"
              class="col-12 col-lg-3 mb-2"
              style="width: 100%"
            ></el-input>
            <div class="col-12 col-lg-2 form-group mb-2">
              <el-select
                v-if="getCamsites"
                v-model="selectedCamsite"
                class="form-dropdown"
                style="width: 100%"
              >
                <el-option value="" label="Any platfom"> </el-option>
                <el-option
                  v-for="camsite in getCamsites"
                  :key="camsite.id"
                  :value="camsite.id"
                  :label="camsite.title"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-12 col-lg-2 form-group mb-2">
              <el-select
                v-model="selectedStatus"
                class="form-dropdown"
                style="width: 100%"
              >
                <el-option value="" label="Online/Offline"></el-option>
                <el-option value="online" label="Online"></el-option>
                <el-option value="offline" label="Offline"></el-option>
              </el-select>
            </div>
            <div class="col-12 col-lg-2 text-center">
              <el-button type="secondary" @click="resetDefault"
                >{{getTrans('messages.reset')}}</el-button
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <select-profiles
              :profiles="filteredProfiles"
              @updated="selectedProfilesUpdated"
            ></select-profiles>
          </div>
        </div>
        <div class="row justify-content-center">
          <el-button type="secondary" @click="closeCollapse">{{getTrans('messages.cancel')}}</el-button>
          <el-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</el-button>
        </div>
      </el-collapse-item>
    </div>
    <div v-if="showModules.indexOf('tracking') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.custom_tracking')" name="3">
        <div class="row align-items-center">
          <custom-tracking
            @updated="selectedCustomTrackingUpdated"
          ></custom-tracking>
        </div>
        <div class="row justify-content-center mt-2">
          <el-button type="secondary" @click="closeCollapse">{{getTrans('messages.cancel')}}</el-button>
          <el-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</el-button>
        </div>
      </el-collapse-item>
    </div>
    <div v-if="showModules.indexOf('settings') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.additional_settings')" name="4">
        <div class="row align-items-center d-flex justify-content-between">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-12 text-center">
                <p>{{getTrans('messages.add_delay')}}
                </p>
                <el-input
                  v-model="secondsDefault"
                  size="medium"
                  type="number"
                  min="0"
                  max="300"
                ></el-input>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-12 text-center align-items-center">
                <p>{{getTrans('messages.add_sound')}}</p>
                <div class="col-12 text-center align-items-center">
                  <div
                    class="row justify-content-center align-items-center mb-2"
                  >
                    <p class="m-2">{{getTrans('messages.sound_num', 1)}}</p>
                    <audio class="m-2" controls>
                      <source
                        src="https://storage.googleapis.com/files.diva.network/ding/ding1.mp3"
                        preload="auto"
                      />
                    </audio>
                    <el-button
                      v-if="selectedSound != 'ding1'"
                      @click="setSound('ding1')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.select')}}</el-button
                    >
                    <el-button
                      v-else
                      @click="setSound('')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.selected')}}</el-button
                    >
                  </div>
                  <div
                    class="row justify-content-center align-items-center mb-2"
                  >
                    <p class="m-2">{{getTrans('messages.sound_num', 2)}}</p>
                    <audio class="m-2" controls>
                      <source
                        src="https://storage.googleapis.com/files.diva.network/ding/ding2.mp3"
                        preload="auto"
                      />
                    </audio>
                    <el-button
                      v-if="selectedSound != 'ding2'"
                      @click="setSound('ding2')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.select')}}</el-button
                    >
                    <el-button
                      v-else
                      @click="setSound('')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.selected')}}</el-button
                    >
                  </div>
                  <div
                    class="row justify-content-center align-items-center mb-2"
                  >
                    <p class="m-2">{{getTrans('messages.sound_num', 3)}}</p>
                    <audio class="m-2" controls>
                      <source
                        src="https://storage.googleapis.com/files.diva.network/ding/ding3.mp3"
                        preload="auto"
                      />
                    </audio>
                    <el-button
                      v-if="selectedSound != 'ding3'"
                      @click="setSound('ding3')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.select')}}</el-button
                    >
                    <el-button
                      v-else
                      @click="setSound('')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.selected')}}</el-button
                    >
                  </div>
                  <div
                    class="row justify-content-center align-items-center mb-2"
                  >
                    <p class="m-2">{{getTrans('messages.sound_num', 4)}}</p>
                    <audio class="m-2" controls>
                      <source
                        src="https://storage.googleapis.com/files.diva.network/ding/ding4.mp3"
                        preload="auto"
                      />
                    </audio>
                    <el-button
                      v-if="selectedSound != 'ding4'"
                      @click="setSound('ding4')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.select')}}</el-button
                    >
                    <el-button
                      v-else
                      @click="setSound('')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.selected')}}</el-button
                    >
                  </div>
                  <div
                    class="row justify-content-center align-items-center mb-2"
                  >
                    <p class="m-2">{{getTrans('messages.sound_num', 5)}}</p>
                    <audio class="m-2" controls>
                      <source
                        src="https://storage.googleapis.com/files.diva.network/ding/ding5.mp3"
                        preload="auto"
                      />
                    </audio>
                    <el-button
                      v-if="selectedSound != 'ding5'"
                      @click="setSound('ding5')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.select')}}</el-button
                    >
                    <el-button
                      v-else
                      @click="setSound('')"
                      class="m-2"
                      type="primary"
                      size="sm"
                      >{{getTrans('messages.selected')}}</el-button
                    >
                  </div>
                </div>
                <div class="col-12">
                  <div class="row justify-content-center mt-2">
                    <el-button type="secondary" @click="closeCollapse"
                      >{{getTrans('messages.cancel')}}</el-button
                    >
                    <el-button type="primary" @click="nextCollapse"
                      >{{getTrans('messages.done')}}</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </div>
    <div v-if="showModules.indexOf('gender') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.gender_select')" name="5">
        <gender-select @updated="genderUpdated"></gender-select>
        <div class="row justify-content-center mt-2">
          <el-button type="secondary" @click="closeCollapse">{{getTrans('messages.cancel')}}</el-button>
          <el-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</el-button>
        </div>
      </el-collapse-item>
    </div>
    <div v-if="showModules.indexOf('thumbnail') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.thumbnail_select')" name="6">
        <thumbnail-select @updated="thumbnailUpdated"></thumbnail-select>
        <div class="row justify-content-center mt-2">
          <el-button type="secondary" @click="closeCollapse">{{getTrans('messages.cancel')}}</el-button>
          <el-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</el-button>
        </div>
      </el-collapse-item>
    </div>
    <div v-if="showModules.indexOf('fallopt') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.fallback_options')" name="7">
        <fallback-options></fallback-options>
        <div class="row justify-content-center mt-2">
          <el-button type="secondary" @click="closeCollapse">{{getTrans('messages.cancel')}}</el-button>
          <el-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</el-button>
        </div>
      </el-collapse-item>
    </div>
    <div v-if="showModules.indexOf('fallurl') >= 0" class="col-12">
      <el-collapse-item :title="getTrans('messages.fallback_url')" name="8">
        <fallback-url @updated="fallbackUrlUpdated"></fallback-url>
        <div class="row justify-content-center mt-2">
          <el-button type="secondary" @click="closeCollapse">{{getTrans('messages.cancel')}}</el-button>
          <el-button type="primary" @click="nextCollapse">{{getTrans('messages.done')}}</el-button>
        </div>
      </el-collapse-item>
    </div>
  </el-collapse>
</template>
<script>
import { mapGetters } from "vuex";
import GenderSelect from "./GenderSelect";
import SelectCamsites from "./SelectCamsites";
import SelectProfiles from "./SelectProfiles";
import CustomTracking from "./CustomTracking";
import ThumbnailSelect from "./ThumbnailSelect";
import FallbackOptions from "./FallbackOptions";
import FallbackUrl from "./FallbackURL";
import {
  ElButton,
  ElRadio,
  ElButtonGroup,
  ElRadioButton,
  ElCollapse,
  ElCollapseItem,
  ElInput,
  ElSelect,
  ElOption,
} from "element-plus";
export default {
  props: ["showModules"],
  data() {
    return {
      activeNames: [],
      selectedSound: "",
      selectedCamsites: "",
      selectedProfiles: [],
      selectedCustomTracking: [],
      selectedCamsite: "",
      currentSearch: "",
      radioactive: "All",
      secondsDefault: "0",
      selectedStatus: "",
      fallbackUrl: {},
    };
  },
  components: {
    GenderSelect,
    SelectCamsites,
    SelectProfiles,
    CustomTracking,
    ThumbnailSelect,
    FallbackOptions,
    FallbackUrl,
    [ElButton.name]: ElButton,
    [ElInput.name]: ElInput,
    [ElOption.name]: ElOption,
    [ElSelect.name]: ElSelect,
    [ElRadio.name]: ElRadio,
    [ElButtonGroup.name]: ElButtonGroup,
    [ElRadioButton.name]: ElRadioButton,
    [ElCollapse.name]: ElCollapse,
    [ElCollapseItem.name]: ElCollapseItem,
  },
  methods: {
    search(sear) {
      this.$store.dispatch("Profile/search", { search: sear, platform_id: "" });
    },
    closeCollapse() {
      this.activeNames = [];
    },
    nextCollapse() {
      this.activeNames = Number(this.activeNames) + 1 + "";
    },
    setSound(sound) {
      this.selectedSound = sound;
      this.update();
    },
    selectedCamsitesUpdated(camsites) {
      this.selectedCamsites = camsites;
      this.update();
    },
    selectedProfilesUpdated(profiles) {
      this.selectedProfiles = profiles;
      this.update();
    },
    selectedCustomTrackingUpdated(customtracking) {
      this.selectedCustomTracking = customtracking;
      this.update();
    },
    genderUpdated(value) {
      this.gender = value;
      this.update();
    },
    thumbnailUpdated(value) {
      this.thumbnail = value;
      this.update();
    },
    fallbackUrlUpdated(value) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      if (pattern.test(value)) {
        this.fallbackUrl.url = btoa(value);
        this.fallbackUrl.valid = true;
      } else {
        this.fallbackUrl.url = "";
        this.fallbackUrl.valid = false;
      }
      this.update();
    },
    update() {
      this.$emit("updated", {
        camsites: this.selectedCamsites,
        profiles: this.selectedProfiles,
        tracking: this.selectedCustomTracking,
        gender: this.gender,
        thumbnail: this.thumbnail ? this.thumbnail : "m",
        settings: { ding: this.selectedSound, delay: this.secondsDefault },
        fallopt: this.fallopt,
        fallurl: this.fallbackUrl,
      });
    },
    selectAllProfiles() {
      this.$root.$emit("selectAllProfiles");
    },
    resetDefault() {
      this.currentSearch = "";
      this.selectedCamsite = "";
      this.selectedStatus = "";
    },
  },
  computed: {
    ...mapGetters("Camsite", ["getCamsites"]),
    ...mapGetters("Profile", {getProfiles: 'getList'}),

    filteredProfiles() {
      var prof = this.getProfiles
        ? this.getProfiles.filter((p) => {
            var correctCamsite =
              this.selectedCamsite && p.performer
                ? p.performer.data.platform_id == this.selectedCamsite
                : true;
            var correctName =
              this.currentSearch && p.performer
                ? p.performer.data.name
                    .toLowerCase()
                    .indexOf(this.currentSearch.toLowerCase()) >= 0
                : true;
            return correctCamsite && correctName;
          })
        : [];
      return prof;
    },
  },
  watch: {
    secondsDefault() {
      this.update();
    },
    selectedSound() {
      this.update();
    },
  },
  mounted() {
    this.$store.dispatch("Camsite/get");
    this.$store.dispatch("Profile/getAll");
  },
};
</script>
