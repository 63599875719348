<template>
  <div class="card-deck justify-content-center">
    <div
      v-for="i in profiles"
      :key="i.id"
      @click="selectProfile(i.id)"
      :class="{
        border: selected[i.id],
        'border-success': selected[i.id],
        grayscale: !selected[i.id]
      }"
      class="random card"
    >
    
      <img
        v-if="i.performer.data.screenshot_url"
        class="card-img"
        :src="i.performer.data.screenshot_url"
      />
      <img
        v-else
        class="card-img"
        src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
      />
      <div
        v-if="getCamsite(i.performer.data.platform_id)"
        class="card-img-overlay profile-overlay"
      >
        <badge type="primary">{{
          getCamsite(i.performer.data.platform_id).title
        }}</badge>
      </div>
      <div class="text-center">
        <span>{{ i.performer.data.name }}</span>
      </div>
      <div class="align-middle text-center">
        <span v-if="!selected[i.id]"
          ><span class="align-middle"
            ><i class="far fa-square checkbox-icon"></i></span
          ><span class="align-top">{{getTrans('messages.select')}}</span></span
        ><span v-else
          ><span class="align-middle"
            ><i
              class="far fa-check-square checkbox-icon checkbox-icon-checked"
            ></i></span
          ><span class="align-top">{{getTrans('messages.selected')}}</span></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ElCheckbox, ElCheckboxButton, ElCheckboxGroup } from "element-plus";
import { mapGetters } from "vuex";
export default {
  name: "select-profiles",
  components: {
    [ElCheckbox.name]: ElCheckbox,
    [ElCheckboxButton.name]: ElCheckboxButton,
    [ElCheckboxGroup.name]: ElCheckboxGroup
  },
  props: ["profiles"],
  data() {
    return {
      selected: []
    };
  },
  computed: {
    ...mapGetters("Camsite", ["getCamsites", "getCamsite"])
  },
  methods: {
    selectProfile(v) {
      this.selected[v] = !this.selected[v];
      this.update();
      this.$forceUpdate();
    },
    stopsclick(e) {
      e.stopPropagation();
    },
    update() {
      this.allSelected = false;
      this.$emit(
        "updated",
        this.profiles.filter(p => {
          return this.selected[p.id];
        })
      );
    }
  },
  mounted() {
    this.$root.$on("selectAllProfiles", () => {
      if (this.allSelected) {
        this.selected = [];
        this.update();
      } else {
        this.selected = [];
        this.profiles.forEach(p => {
          this.selected[p.id] = true;
        });
        this.update();
        this.allSelected = true;
      }
    });
  }
};
</script>
<style scoped>
.random {
  flex: 0 0 14%;
}

.grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.checkbox-icon {
  padding-right: 5px;
  font-size: large;
}

.checkbox-icon-checked {
  color: rgb(45, 206, 137);
}

.profile-overlay {
  padding-left: 2px;
  padding-top: 0px;
}
</style>
