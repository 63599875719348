<template>
  <div class="card-deck" v-if="getCamsites">
    <div
      v-for="i in getCamsites"
      :key="i.id"
      @click="selectCamsite(i)"
      :class="{
        border: selected[i.id],
        'border-success': selected[i.id],
        grayscale: !selected[i.id],
      }"
      class="random card profile-card"
    >
      <img
        class="card-img"
        :src="platformLogo(i.id)"
        :alt="i.title"
      />
      <div class="align-middle text-center">
        <span v-if="!selected[i.id]"
          ><span class="align-middle"
            ><i class="far fa-square checkbox-icon"></i></span
          ><span class="align-top">{{getTrans('messages.selected')}}</span></span
        ><span v-else
          ><span class="align-middle"
            ><i
              class="far fa-check-square checkbox-icon checkbox-icon-checked"
            ></i></span
          ><span class="align-top">{{getTrans('messages.selected')}}</span></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PlatformMixin from "@/mixins/PlatformMixin";
export default {
  name: "select-camsites",
  mixins: [PlatformMixin],
  emits: ["updated"],
  props: { defaultSelected: [Array, String], refresh: Number },
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    refresh() {
      this.resetDefault();
    },
  },
  methods: {
    selectCamsite(v) {
      this.selected[v.id] = !this.selected[v.id];
      this.update();
      this.$forceUpdate();
    },
    stopsclick(e) {
      e.stopPropagation();
    },
    update() {
      this.$emit(
        "updated",
        this.getCamsites.filter((c) => {
          return this.selected[c.id];
        })
      );
    },
    resetDefault() {
      if (Array.isArray(this.defaultSelected)) {
        this.defaultSelected.forEach((c) => {
          this.selected[c] = true;
        });
      } else if (this.defaultSelected == "all") {
        this.getCamsites.forEach((c) => {
          this.selected[c.id] = true;
        });
      }
      this.update();
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapGetters("Platform", ["getCamsites"]),
  },
  created() {
    if (this.getCamsites) {
      this.resetDefault();
    }
  },
};
</script>
<style scoped>
.random {
  flex: 0 0 14%;
}

.grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.checkbox-icon {
  padding-right: 5px;
  font-size: large;
}

.checkbox-icon-checked {
  color: rgb(45, 206, 137);
}
</style>
