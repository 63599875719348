<template>
  <div class="row align-items-end">
    <div class="col-12 col-lg-3 text-center mt-4">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          {{getTrans('messages.select_thumb')}}
        </li>
      </ul>
    </div>
    <div class="col-12 col-lg-9 text-center">
      <el-radio-group v-model="sizeactive" class="d-sm-inline-flex">
        <el-radio-button label="xs">{{getTrans('messages.xsmall')}}</el-radio-button>
        <el-radio-button label="s">{{getTrans('messages.small')}}</el-radio-button>
        <el-radio-button label="m">{{getTrans('messages.medium')}}</el-radio-button>
        <el-radio-button label="l">{{getTrans('messages.large')}}</el-radio-button>
        <el-radio-button label="xl">{{getTrans('messages.xlarge')}}</el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>
<script>
import { ElButtonGroup, ElRadioButton } from "element-plus";
export default {
  components: {
    [ElRadioButton.name]: ElRadioButton,
    [ElButtonGroup.name]: ElButtonGroup,
  },
  name: "thumbnail-select",
  data() {
    return {
      sizeactive: "m"
    };
  },
  watch: {
    sizeactive() {
      this.$emit("updated", this.sizeactive);
    }
  }
};
</script>
