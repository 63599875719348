<template>
  <div class="row align-items-end text-center">
    <div class="col-12 col-lg-3 mt-4">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          {{getTrans('messages.select_gender')+':'}}
        </li>
      </ul>
    </div>
    <div class="col-12 col-lg-9 text-center">
      <el-radio-group v-model="genderactive" class="d-sm-inline-flex">
        <el-radio-button label="">{{getTrans('messages.all')}}</el-radio-button>
        <el-radio-button label="f">{{getTrans('messages.female')}}</el-radio-button>
        <el-radio-button label="m">{{getTrans('messages.male')}}</el-radio-button>
        <el-radio-button label="t">{{getTrans('messages.trans')}}</el-radio-button>
        <el-radio-button label="c">{{getTrans('messages.couple')}}</el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>
<script>
import { ElButtonGroup, ElRadioButton } from "element-plus";
export default {
  name: "gender-select",
  components: {
    [ElRadioButton.name]: ElRadioButton,
    [ElButtonGroup.name]: ElButtonGroup,
  },
  data() {
    return {
      genderactive: ""
    };
  },

  watch: {
    genderactive() {
      this.$emit("updated", this.genderactive);
    }
  }
};
</script>
